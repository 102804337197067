import { Button } from '@vcc-www/buttons';
import React, { type FC } from 'react';
import { type ExtendCSS, Flex } from 'vcc-ui';

export type ctaType = {
  href: string | undefined;
  text: string | undefined;
  target: string | undefined;
};

type Props = {
  cta1?: ctaType;
  cta2?: ctaType;
  intent?: 'primary' | 'secondary' | 'destructive';
  withTexts: boolean;
  desktopTextPosition?: string;
};

const Component: FC<React.PropsWithChildren<Props>> = ({
  cta1,
  cta2,
  intent,
  desktopTextPosition,
  withTexts,
}) => {
  const wrapperCSS = withTexts
    ? withTextCSS(desktopTextPosition)
    : withoutTextCSS();

  return (
    <Flex extend={wrapperCSS}>
      {cta1?.text && (
        <div>
          <Button
            intent={intent}
            variant="default"
            aria-label={cta1.text}
            href={cta1.href}
            target={cta1.target}
            trackEventLabel={`${cta1.text} | ${cta1.href}`}
          >
            {cta1.text}
          </Button>
        </div>
      )}
      {cta2?.text && (
        <div>
          <Button
            intent={intent}
            variant="outline"
            aria-label={cta2.text}
            href={cta2.href}
            target={cta2.target}
            trackEventLabel={`${cta2.text} | ${cta2.href}`}
          >
            {cta2.text}
          </Button>
        </div>
      )}
    </Flex>
  );
};

const withTextCSS =
  (desktopTextPosition?: string): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    onlyS: {
      display: 'flex',
    },
    onlyM: {
      display: 'flex',
    },
    paddingTop: '0',
    paddingRight: '0',
    paddingLeft: '0',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    pointerEvents: 'auto',
    gap: `${baselineGrid * 2}px  ${baselineGrid * 3}px`,
    fromL: {
      justifyContent: 'flex-start',
      paddingTop: '0',
      paddingRight: '0',
      paddingLeft: '0',
      ...(desktopTextPosition === 'center' && {
        justifyContent: 'center',
      }),
      ...((desktopTextPosition === 'middleRight' ||
        desktopTextPosition === 'bottomRight') && {
        justifyContent: 'flex-end',
      }),
    },
  });

const withoutTextCSS =
  (): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    onlyS: {
      display: 'flex',
    },
    onlyM: {
      display: 'flex',
    },
    paddingTop: baselineGrid * 2,
    paddingRight: baselineGrid * 3,
    paddingLeft: baselineGrid * 3,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    pointerEvents: 'auto',
    gap: `${baselineGrid * 2}px  ${baselineGrid * 3}px`,
    fromL: {
      display: 'none',
    },
  });

export default Component;
